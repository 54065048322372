<template>
  <div class="form-group form-group-input">
    <input
        class="form-input"
        :id="uid"
        v-bind="$attrs"
        :value="modelValue"
        :placeholder="placeholder ? placeholder : label"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    <label
        class="form-label"
        :for="uid"
        v-if="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ["update:modelValue"],
    data() {
    return {
      uid: null
    }
  },
  mounted() {
    this.uid = uuidv4();
  }
}
</script>