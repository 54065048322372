<template>
  <footer>
    <div class="container">
      <ul>
        <li>Created by <a href="https://www.mydjango.cz">MyDjango.cz</a></li>
        <li>Build-python-from-source.com © 2024</li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>
</style>