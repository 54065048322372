<template>
  <div class="form-group form-group-select">
    <div class="form-select-box">
      <select
          class="form-select"
          :value="modelValue"
          v-bind="{
      ...$attrs,
      onChange: ($event) => {$emit('update:modelValue', $event.target.value) }
    }"
      >
        <!--    <option selected disabled>Choose</option>-->
        <option
            v-for="(option, index) in options"
            :key="index"
            :value="option"
            :selected="option === modelValue"
        >{{ option }}
        </option>
      </select>
      <span class="form-select-marker"></span>
    </div>
    <label class="form-label"
           v-if="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ["update:modelValue"],
}
</script>