<!--        <BaseSelect-->
<!--            v-model="curr.osVer"-->
<!--            :options="selectData.osVer"-->
<!--            :label="config.ui.select.selectOsVer.label"-->
<!--        />-->

<!--            <BaseCheckbox-->
<!--                v-model="curr.hasComments"-->
<!--                :label="config.ui.checkbox.hasComments.label"-->
<!--            />-->
<!--            <br>-->

<!--            <BaseCheckbox-->
<!--                v-model="curr.hasSqliteSupport"-->
<!--                :label="config.ui.checkbox.hasSqliteSupport.label"-->
<!--            />-->
<!--            <br>-->
<!--            <BaseCheckbox-->
<!--                v-model="curr.hasOpensslSupport"-->
<!--                :label="config.ui.checkbox.hasOpensslSupport.label"-->
<!--            />-->
<!--            <br>-->

<!--            <BaseInput v-model="curr.sharedLibraryFolder"-->
<!--                       :disabled="!curr.hasSharedLibrary"-->
<!--                       :label="config.ui.input.sharedLibrary.label"-->
<!--            />-->
<!--            <br>-->

<template>
  <main>

    <section class="hero">
      <div class="container">
        <div class="row">


          <!--          <img alt="Python logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/110px-Python-logo-notext.svg.png" decoding="async" -->
          <!--               srcset="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/165px-Python-logo-notext.svg.png 1.5x, https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/220px-Python-logo-notext.svg.png 2x" config-file-width="110" config-file-height="110" width="110" height="110">-->

          <div class="headline-box">
            <img class="svg" alt="Python logo" src="@/assets/img/python-logo.svg">
            <h1 class="headline">Build, compile and install Python from source code</h1>
          </div>

          <ul class="list-subheadline">
            <li>
              <h2 class="subheadline"><span>&check;</span> Compile, build and install Python 2.7 or 3.x binaries from source code</h2>
            </li>
            <li>
              <h2 class="subheadline"><span>&check;</span> Use the generated copy & paste bash script</h2>
            </li>
            <li>
              <h2 class="subheadline"><span>&check;</span> Don’t worry, the shipped Python in your Linux box is going to keep untouched</h2>
            </li>
          </ul>

        </div>
      </div>
    </section>

    <section class="control">
      <div class="container">
        <div class="row">

          <div class="block">
            <h3 class="sub2headline">Choose Python</h3>
            <BaseButtonGroup @triggerClick="setPythonFam($event)" :options="Object.keys(data.python)" :value="curr.pythonFam"/>
            <BaseSelect
                v-model="curr.pythonVer"
                :options="selectData.pythonVer"
                :label="data.ui.select.selectPythonVer.label"
            />
          </div>

          <div class="block">
            <h3 class="sub2headline">Compilation options</h3>
            <BaseInput
                v-model="curr.prefixFolder"
                :label="data.ui.input.prefix.label"
                :placeholder="data.ui.input.prefix.placeholder"
            />
            <BaseToggle
                v-model="curr.hasUseAllCpus"
                :label="data.ui.checkbox.hasUseAllCpus.label"
            />
            <BaseToggle
                v-model="curr.hasSpeedOptimization"
                :label="data.ui.checkbox.hasSpeedOptimization.label"
            />
            <BaseToggle
                v-model="curr.hasSharedLibrary"
                :label="data.ui.checkbox.hasSharedLibrary.label"
            />
            <BaseToggle
                v-model="curr.hasTestBinary"
                :label="data.ui.checkbox.hasTestBinary.label"
            />
          </div>

          <div class="block">
            <h3 class="sub2headline">Post installation stuff</h3>
            <BaseToggle
                v-model="curr.hasUpdatePip"
                :label="data.ui.checkbox.hasUpdatePip.label"
            />
            <BaseToggle
                v-model="curr.hasSomeLinks"
                :label="data.ui.checkbox.hasSomeLinks.label"
            />
          </div>

          <div class="block">
            <h3 class="sub2headline">OS related stuff</h3>
            <BaseToggle
                v-model="curr.hasInstallPackages"
                :label="data.ui.checkbox.hasInstallPackages.label"
            />
            <div v-if="curr.hasInstallPackages">
              <!--              <h3>Choose operation system</h3>-->
              <BaseButtonGroup @triggerClick="setOsFam($event)" :options="Object.keys(data.os)" :value="curr.osFam" :disabled="!curr.hasInstallPackages"/>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="code">
      <div class="container">
        <div class="row">
          <CodePlace :code="code" class="codePlace" title="Copy & Paste bash script - Build, compile and install Python from source code."/>
        </div>
      </div>
    </section>

    <!--        <section class="vars">-->
    <!--        <div class="row">-->
    <!--          <ShowVar :curr="curr"/>-->
    <!--        </div>-->
    <!--        </div>-->

  </main>
  <Footer/>
</template>

<script>
// import {useMeta} from 'vue-meta';
import {config, initValue, script} from '@/dassets/config';
import BaseInput from "@/components/BaseInput";
import BaseSelect from "@/components/BaseSelect";
import BaseButtonGroup from "@/components/BaseButtonGroup";
import BaseToggle from "@/components/BaseToggle";
import Vars from "@/components/Vars";
import CodePlace from "@/components/CodePlace";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    BaseInput,
    BaseSelect,
    BaseButtonGroup,
    BaseToggle,
    CodePlace,
    Vars,
    Footer
  },
  // setup() {
  //   useMeta({
  //     title: 'Build, compile and install Python 3 from source code',
  //     htmlAttrs: {
  //       lang: 'en'
  //     }
  //   })
  // },
  data() {
    return {
      data: config,
      code: "",
      selectData: {
        pythonVer: initValue.pythonVerSelectOptions,
        osVer: initValue.osVerSelectOptions,
      },
      curr: {
        osFam: initValue.osFam,
        osVer: initValue.osVer,
        pythonFam: initValue.pythonFam,
        pythonVer: initValue.pythonVer,
        url: null,
        hasComments: initValue.hasComments,
        hasInstallPackages: initValue.hasInstallPackages,
        hasUseAllCpus: initValue.hasUseAllCpus,
        hasSharedLibrary: initValue.hasSharedLibrary,
        hasOpensslSupport: initValue.hasOpensslSupport,
        hasSqliteSupport: initValue.hasSqliteSupport,
        hasSpeedOptimization: initValue.hasSpeedOptimization,
        hasTestBinary: initValue.hasTestBinary,
        hasUpdatePip: initValue.hasUpdatePip,
        hasSomeLinks: initValue.hasSomeLinks,
        prefixFolder: initValue.prefixFolder,
        sharedLibraryFolder: initValue.sharedLibraryFolder,
      },
    }
  },
  methods: {
    calcUrl() {
      this.curr.url = this.data.python[this.curr.pythonFam].ver[this.curr.pythonVer].url
    },
    calcPrefix() {
      this.curr.prefixFolder = `${initValue.prefixBaseFolder}${this.curr.pythonVer}/`
    },
    setOsFam(val) {
      const ver_arr = Object.keys(this.data.os[val].ver).sort((a, b) => a - b).reverse()
      this.selectData.osVer = ver_arr
      this.curr.osFam = val
      this.curr.osVer = ver_arr[0]
    },
    setPythonFam(val) {
      const ver_arr = Object.keys(this.data.python[val].ver).sort((a, b) => a - b)
      this.selectData.pythonVer = ver_arr
      this.curr.pythonFam = val
      this.curr.pythonVer = ver_arr[0]
      this.calcPrefix()
    },
    generateCode() {
      this.calcUrl()
      this.calcPrefix()

      var NL = "\n"
      var DOUBLE_NL = "\n\n"
      // var SPACE = " "
      var code_arr = []

      //----------

      function chooseData(data_feature, curr_version) {
        var d_arr

        if (data_feature[curr_version]) {
          d_arr = data_feature[curr_version]
        } else {
          d_arr = data_feature.common
        }

        return d_arr
      }

      //--------------------------------------------


      if (this.curr.hasInstallPackages) {
        code_arr.push(chooseData(script.packages, this.curr.osFam).join(NL))
        code_arr.push(DOUBLE_NL)
      }

      //----------

      var prepare = script.prepare.join(NL)
      code_arr.push(prepare)
      code_arr.push(DOUBLE_NL)

      //----------

      var compile_configure = script.compile_configure.join(NL)

      if (this.curr.hasSpeedOptimization) {
        compile_configure = `${compile_configure} ${script.compile_configure__hasSpeedOptimization.join(NL)}`
      }

      if (this.curr.hasSharedLibrary) {
        compile_configure = `${compile_configure} ${script.compile_configure__hasSharedLibrary.join(NL)}`
      }

      code_arr.push(compile_configure)
      code_arr.push(NL)

      //----------

      var compile_make = script.compile_make.join(NL)

      if (this.curr.hasUseAllCpus) {
        compile_make = `${compile_make} ${script.compile_make__hasUseAllCpus.join(NL)}`
      }

      code_arr.push(compile_make)
      code_arr.push(NL)

      //----------

      if (this.curr.hasTestBinary) {

        var compile_testBinaries = script.compile_testBinaries.join(NL)

        if (this.curr.hasUseAllCpus) {
          compile_testBinaries = `${compile_testBinaries} ${script.compile_testBinaries__hasUseAllCpus.join(NL)}`
        }

        code_arr.push(compile_testBinaries)
        code_arr.push(NL)
      }

      //----------

      code_arr.push(script.compile_post.join(NL))
      code_arr.push(DOUBLE_NL)

      //----------

      if (this.curr.hasUpdatePip) {

        code_arr.push(chooseData(script.pipUpdate, this.curr.pythonFam).join(NL))
        code_arr.push(DOUBLE_NL)
      }

      //----------

      if (this.curr.hasSomeLinks) {
        code_arr.push(script.links.join(NL))
        code_arr.push(NL)
      }

      //----------------------------------------------------------------------------------------------------------------------------

      let codeContent = code_arr.join("")

      const replacePattern_arr = [
        ["[[pythonFam]]", this.curr.pythonFam],
        ["[[pythonVer]]", this.curr.pythonVer],
        ["[[prefix]]", this.curr.prefixFolder.replace(/\/+$/, '')],  // to solve multiple trailing slashes
        ["[[url]]", this.curr.url],
      ]

      replacePattern_arr.forEach(item => {
        codeContent = codeContent.replaceAll(item[0], item[1])
      })

      this.code = codeContent.replace(/\n+$/, '')

    }
    ,
  },
  mounted() {
    this.generateCode()
  },
  watch: {
    curr: {
      handler() {
        this.generateCode()
      },
      deep: true
    }
  },
}

</script>

<style lang="scss" scoped>


//
//.row.btn {
//
//  display: flex;
//  justify-content: space-between;
//  align-items: start;
//
//  .col {
//    h3 {
//      margin-bottom: 0.3rem;
//    }
//  }
//}
//
//.row.checkbox {
//  display: flex;
//  justify-content: space-between;
//  align-items: start;
//}
//
//.codePlace {
//  margin-top: 50px;
//}

</style>