<template>
  <div class="form-group btn-group">
    <button class="btn"
            v-for="option in options" :key="option"
            v-bind="$attrs"
            @click="$emit('triggerClick', option)"
            :class="{'active': option === value && !disabled}"
            :disabled="disabled">
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ""
    },
    options: {
      type: [Array, Object],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style>
</style>


<!--    <ul>-->
<!--      <li v-for="option in options" :key="option">-->
<!--        <button class="btn"-->
<!--                v-bind="$attrs"-->
<!--                @click="$emit('triggerClick', option)"-->
<!--                :class="{'active': option === value && !disabled}"-->
<!--                :disabled="disabled">-->
<!--          {{ option }}-->
<!--        </button>-->
<!--      </li>-->
<!--    </ul>-->