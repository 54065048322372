<template>
  <div class="show">
    <div>
      python: {{ curr.pythonFam }} <br>
      python_ver: {{ curr.pythonVer }}
    </div>
    <div>
      os: {{ curr.osFam }} <br>
      os_ver: {{ curr.osVer }}
    </div>
    <div>
      hasComments: {{ curr.hasComments }} <br>
      hasInstallPackages: {{ curr.hasInstallPackages }} <br>
      hasUseAllCpus: {{ curr.hasUseAllCpus }}
    </div>
    <div>
      prefixFolder: {{ curr.prefixFolder }} <br>
      sharedLibraryFolder: {{ curr.sharedLibraryFolder }} <br>
      url: {{ curr.url }} <br>
    </div>
    <div>
      hasSpeedOptimization: {{ curr.hasSpeedOptimization }} <br>
      hasSqliteSupport: {{ curr.hasSqliteSupport }} <br>
      hasOpensslSupport: {{ curr.hasOpensslSupport }} <br>
      hasSharedLibrary: {{ curr.hasSharedLibrary }}
    </div>
    <div>
      hasTestBinary: {{ curr.hasTestBinary }} <br>
      hasUpdatePip: {{ curr.hasUpdatePip }} <br>
      hasSomeLinks: {{ curr.hasSomeLinks }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowVar",
  props: {
    curr: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

.show {
  margin-top: 6rem;
  margin-bottom: 1rem;
  font-size: 12px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  gap: 0 1rem;

  > div {
    padding: 0.5rem;
    outline: #a0a0a0 dashed 1px;
    place-content: center;
  }
}
</style>