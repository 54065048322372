<template>
  <pre v-bind="$attrs" v-if="code" class="code-place">
{{ code }}<button @click.prevent="copyToClipboard()" class="btn-clipboard" :class="{ 'is-copied':isCopied }">{{ !isCopied ? 'Copy to clipboard':' Code has been added to clipboard!'}}</button>
  </pre>
</template>

<script>

export default {
  name: "CodePlace",
  inheritAttrs: false,
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.$props.code);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = !this.isCopied
      }, 2000);
    }
  }
}
</script>

<style>
</style>