<template>
  <div class="form-group form-group-toggle">
    <label class="form-toggle-box"
           :for="uid">

      <input
          class="form-input"
          type="checkbox"
          :id="uid"
          :checked=modelValue
          @change="$emit('update:modelValue', $event.target.checked)"
      />

      <div class="form-fill"></div>
      {{ label }}
    </label>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "BaseToggle",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      uid: null
    }
  },
  mounted() {
    this.uid = uuidv4();
  }
}
</script>