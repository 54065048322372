import {createApp} from 'vue';
import App from './App.vue';
// import VueClipboard from 'vue3-clipboard';
// import VueGtag from "vue-gtag-next";


// global css style
import "@/assets/scss/style.scss"
//
// const app = createApp(App);
// app.use(VueClipboard, {
//   autoSetContainer: false,
//   appendToBody: true,
// })
// https://matteo-gabriele.gitbook.io/vue-gtag/v/next/
// app.use(VueGtag, {
//     isEnabled: true,
//     property: {
//         id: "UA-38983969-5"
//     }
// });

// createApp(App).use(VueClipboard).mount('#app')

createApp(App).mount('#app')